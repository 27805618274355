<template>
  <modal
    :name="modalName"
    adaptive
    :width="600"
    @before-open="beforeOpen"
    :clickToClose="false"
    height="auto"
    class="check--booking-modal"
  >
    <div class="modal--wrapper">
      <div class="back--wrapper hide--in-desktop hide--in-tablet">
        <div class="back--button" @click="close()">
          <img src="@/assets/img/icons/back-2.svg" />
        </div>
        Isi Informasi Kartu
      </div>
      <SectionLoader :show="loading" />
      <button type="button" class="close hide--in-mobile" @click="close">
        <img src="@/assets/img/icons/close-1.svg" />
      </button>
      <cc-input v-show="!card3dsUrl" @successGetToken="onSuccessGetToken" />
      <div v-if="card3dsUrl">
        <div class="title--text hide--in-mobile">Isi Informasi Kartu</div>
        <iframe :src="card3dsUrl" width="600" height="460" style="width: 100%"></iframe>
      </div>
    </div>
  </modal>
</template>

<script>
const SectionLoader = () => import('@/components/content-loading/section-loading');
const CcInput = () => import('@/components/checkout/partials/cc-input');

export default {
  name: 'cancel-booking',
  components: {
    SectionLoader,
    CcInput,
  },
  data: () => ({
    modalName: 'modal-cc-payment',
    message: '',
    uuid: '',
    card3dsUrl: null,
    orderUuid: null,
  }),
  computed: {
    duration: {
      get() {
        return this.$store.state.cardPayment.duration;
      },
      set(value) {
        this.$store.commit('cardPayment/SET_DURATION', value);
      },
    },
    directActivation: {
      get() {
        return this.$store.state.cardPayment.directActivation;
      },
      set(value) {
        this.$store.commit('cardPayment/SET_DIRECT_ACTIVATION', value);
      },
    },
    price: {
      get() {
        return this.$store.state.cardPayment.price;
      },
      set(value) {
        this.$store.commit('cardPayment/SET_PRICE', value);
      },
    },
    loading: {
      get() {
        return this.$store.state.cardPayment.loading;
      },
      set(value) {
        this.$store.commit('cardPayment/SET_LOADING', value);
      },
    },
  },
  methods: {
    close() {
      this.$modal.hide(this.modalName);
    },
    beforeOpen(event) {
      this.$store.dispatch('cardPayment/resetCardInfo');
      this.duration = event.params.duration;
      this.directActivation = event.params.directActivation;
      this.price = event.params.price;
      this.orderUuid = event.params.orderUuid;
    },
    async onSuccessGetToken(token) {
      let data;
      if (this.orderUuid) {
        data = await this.$store.dispatch('checkout/pay/attemptPay', {
          orderUuid: this.orderUuid,
          cardTokenId: token,
        });
      } else {
        data = await this.$store.dispatch('checkout/pay/submit', {
          totalNetPrice: this.price,
          directActivation: this.directActivation,
          duration: this.duration,
          cardTokenId: token,
        });
      }
      console.log('resss success get token', data);
      if (data.card_3ds_url !== null) {
        var redirectUrl = data.card_3ds_url;

        // callback functions
        var options = {
          performAuthentication: (redirect_url) => {
            console.log('performAuthentication:', redirect_url);

            // Implement how you will open iframe to display 3ds authentication redirect_url to customer
            this.card3dsUrl = redirect_url;
          },
          onSuccess: async (response) => {
            // 3ds authentication success, implement payment success scenario
            console.log('onSuccess:', response);
            await this.$swal('Berhasil!', 'Pembayaran berhasil dilakukan.', 'success');
            this.$router.replace(`/order?q=${data.order_number}`);
          },
          onFailure: async (response) => {
            // 3ds authentication failure, implement payment failure scenario
            console.log('onFailure:', response);
            await this.$swal(
              'Gagal Melakukan Pembayaran',
              'Pembayaran gagal dilakukan, silahkan coba lagi nanti atau gunakan kartu lain.',
              'warning',
            );
            if (data.payment === true) {
              if (data.order_uuid) {
                if (!this.orderUuid) {
                  this.$router.replace(`/how-to-pay/${data.order_uuid}`);
                } else {
                  this.$router.go();
                }
              } else {
                this.$swal(this.$i18n.t('booking.failedPayment'), data.message, 'error');
              }
            } else if (data.payment === false) {
              this.$router.replace(`/order?q=${data.order_number}`);
            }
          },
          onPending: function (response) {
            // transaction is pending, transaction result will be notified later via
            // HTTP POST notification, implement as you wish here
            console.log('onPending:', response);
          },
        };

        // trigger `authenticate` function
        window.MidtransNew3ds.authenticate(redirectUrl, options);
      } else {
        await this.$swal('Berhasil!', 'Pembayaran berhasil dilakukan.', 'success');
        this.$router.replace(`/order?q=${data.order_number}`);
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/utils/variables';
.title--text {
  text-align: center;
  margin-bottom: 12px;
  font-family: Poppins-Bold, sans-serif;
  font-size: $font-xl;
}
</style>
