<template>
  <div class="payment--method-wrapper">
    <div
      class="payment--method"
      v-if="checkoutInfo && checkoutInfo.payment_methods && checkoutInfo.payment_methods.length > 0"
    >
      <div class="top--part">
        <span>{{ $t('booking.headerTitle') }}</span>
      </div>
      <div class="middle--part">
        <div class="payment--method-group">
          <div class="group--title">
            <span>{{ $t('booking.virtualAccount') }}</span>
            <!--            <span class="required">*</span>-->
          </div>

          <v-radio-group v-model="selectedPaymentMethod" class="group--content">
            <!-- BCA VA -->
            <radio-payment-method
              payment-method="bca_va"
              v-if="checkoutInfo.payment_methods.includes('bca_va')"
            ></radio-payment-method>

            <!-- Permata VA -->
            <radio-payment-method
              payment-method="permata_va"
              v-if="checkoutInfo.payment_methods.includes('permata_va')"
            ></radio-payment-method>

            <!-- BNI VA -->
            <radio-payment-method
              payment-method="bni_va"
              v-if="checkoutInfo.payment_methods.includes('bni_va')"
            ></radio-payment-method>

            <!-- BRI VA -->
            <radio-payment-method
              payment-method="bri_va"
              v-if="checkoutInfo.payment_methods.includes('bri_va')"
            ></radio-payment-method>
          </v-radio-group>

          <div class="checkout--terms mt-4">
            <div class="text-muted">{{ $t('booking.message1') }}</div>
          </div>
        </div>

        <div
          class="payment--method-group"
          v-if="
            checkoutInfo.payment_methods.includes('alfa_group') ||
            checkoutInfo.payment_methods.includes('indomaret')
          "
        >
          <div class="group--title">
            <span>{{ $t('booking.gerai') }}</span>
          </div>
          <v-radio-group v-model="selectedPaymentMethod" class="group--content">
            <radio-payment-method
              :payment-method="'alfa_group'"
              v-if="checkoutInfo.payment_methods.includes('alfa_group')"
            ></radio-payment-method>
            <radio-payment-method
              :payment-method="'indomaret'"
              v-if="checkoutInfo.payment_methods.includes('indomaret')"
            ></radio-payment-method>
          </v-radio-group>
        </div>

        <div class="payment--method-group" v-if="checkoutInfo.payment_methods.includes('gopay')">
          <div class="group--title">
            <span>{{ $t('booking.wallet') }}</span>
          </div>
          <v-radio-group v-model="selectedPaymentMethod" class="group--content">
            <radio-payment-method
              :payment-method="'gopay'"
              v-if="checkoutInfo.payment_methods.includes('gopay')"
            ></radio-payment-method>
          </v-radio-group>
        </div>
        <div
          class="payment--method-group"
          v-if="checkoutInfo.payment_methods.includes('credit_card')"
        >
          <div class="group--title">
            <span>{{ $t('booking.creditCard') }}</span>
          </div>
          <v-radio-group v-model="selectedPaymentMethod" class="group--content">
            <radio-payment-method
              :payment-method="'credit_card'"
              v-if="checkoutInfo.payment_methods.includes('credit_card')"
            ></radio-payment-method>
          </v-radio-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RadioPaymentMethod from '@/components/checkout/partials/radio-payment-method.vue';
export default {
  name: 'payment-method',
  components: { RadioPaymentMethod },
  computed: {
    checkoutInfo() {
      return this.$store.state.checkout.checkoutInfo;
    },
    selectedPaymentMethod: {
      get() {
        return this.$store.state.checkout.pay.paymentMethod;
      },
      set(val) {
        this.$store.commit('checkout/pay/SET_PAYMENT_METHOD', val);
      },
    },
  },
  methods: {
    async payNow() {
      this.$emit('submit');
    },
  },
};
</script>

<style lang="scss" scoped>
.payment--logo {
  width: 40px;
  margin-left: 8px;
}
</style>
