<template>
  <v-radio class="method--radio" :value="paymentMethod" color="black">
    <template v-slot:label>
      <div class="single--method" v-if="paymentMethod === 'permata_va'">
        <img src="@/assets/img/icons/payments/permata.svg" :alt="$t('booking.permataVA')" />
        <div class="method--name">Permata Virtual Account<span class="required">*</span></div>
      </div>
      <div class="single--method" v-if="paymentMethod === 'bni_va'">
        <img src="@/assets/img/icons/payments/bni.svg" :alt="$t('booking.bniVA')" />
        <div class="method--name">BNI Virtual Account<span class="required">*</span></div>
      </div>
      <div class="single--method" v-if="paymentMethod === 'bri_va'">
        <img src="@/assets/img/icons/payments/bri.svg" :alt="$t('booking.briVA')" />
        <div class="method--name">BRI Virtual Account<span class="required">*</span></div>
      </div>
      <div class="single--method" v-if="paymentMethod === 'bca_va'">
        <img src="@/assets/img/icons/payments/bca.svg" :alt="$t('booking.bcaVa')" />
        <div class="method--name">
          BCA Virtual Account
          <!--                  <span class="required">*</span>-->
        </div>
      </div>
      <div class="single--method" v-if="paymentMethod === 'gopay'">
        <img src="@/assets/img/icons/payments/gopay.png" :alt="'GoPay'" />
        <div class="method--name">GoPay / QRIS</div>
      </div>
      <div class="single--method" v-if="paymentMethod === 'alfa_group'">
        <img src="@/assets/img/icons/payments/alfamart.svg" :alt="'Alfamart'" />
        <div class="method--name">Alfamart / Alfamidi / Dan+Dan</div>
      </div>
      <div class="single--method" v-if="paymentMethod === 'indomaret'">
        <img src="@/assets/img/icons/payments/indomaret.svg" :alt="'Indomaret'" />
        <div class="method--name">Indomaret</div>
      </div>
      <div class="single--method" v-if="paymentMethod === 'credit_card'">
        <img
          src="@/assets/img/icons/payments/credit-card.png"
          :alt="$t('booking.creditCardMidtrans')"
        />
        <div class="method--name">Visa / Mastercard / JCB / American Express</div>
      </div>
    </template>
  </v-radio>
</template>

<script>
export default {
  name: 'RadioPaymentMethod',
  components: {},
  props: {
    paymentMethod: {
      default: null,
      require: true,
    },
  },
  data: () => ({}),
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/utils/variables';
.method--radio {
  display: flex;
  align-items: center;
}
.single--method {
  display: flex;
  align-items: center;
  img {
    height: 60px;
    width: 90px;
    object-fit: contain;
  }
  .method--name {
    color: $color-text-black;
    font-size: $font-ml;
    margin-left: 12px;
  }
}
</style>
