<template>
  <default-layout :breadcrumbs="breadcrumbs">
    <div class="container checkout--container extra--padding">
      <page-loading :show="loading" />
      <div class="left--col-container">
        <div class="checkout--content">
          <product-info />
          <payment-method
            v-if="checkoutInfo && checkoutInfo.total_net_price > 0"
            @submit="submit()"
          />
          <promo-section />
        </div>
      </div>
      <div class="right--col-container">
        <checkout-info @submit="submit()" />
        <checkout-terms></checkout-terms>
      </div>
    </div>
    <cc-payment-modal />
    <client-only>
      <script
        id="midtrans-script"
        src="https://api.midtrans.com/v2/assets/js/midtrans-new-3ds.min.js"
        :data-environment="midtransMode"
        :data-client-key="midtransClientKey"
        type="text/javascript"
      ></script>
    </client-only>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
import DefaultHeadMixin from '@/mixins/defaultHead';
import PageLoading from '@/components/content-loading/page-loading';
import ProductInfo from '@/components/checkout/product-info';
import PaymentMethod from '@/components/checkout/payment-method';
import CheckoutInfo from '@/components/checkout/checkout-info';
import PromoSection from '@/components/checkout/promo-section';
import CheckoutTerms from '@/components/checkout/checkout-terms';
import CcPaymentModal from '@/components/checkout/modals/cc_payment_modal';
import { mapState } from 'vuex';
import { decryptToken } from '@/utils/helpers';

export default {
  name: 'index',
  // head: {
  //   script: [
  //     {
  //       src:
  //         process.env.NODE_ENV !== 'production'
  //           ? 'https://app.sandbox.midtrans.com/snap/snap.js'
  //           : 'https://app.midtrans.com/snap/snap.js',
  //       'data-client-key':
  //         process.env.NODE_ENV !== 'production'
  //           ? 'SB-Mid-client-xGrFbeIAyF57UyPC'
  //           : 'Mid-client-yJe5bOStNIlZBnkR',
  //     },
  //   ],
  // },
  mixins: [DefaultHeadMixin],
  components: {
    DefaultLayout,
    PageLoading,
    ProductInfo,
    PaymentMethod,
    CheckoutInfo,
    PromoSection,
    CheckoutTerms,
    CcPaymentModal,
  },
  computed: {
    ...mapState({
      loading: (state) => state.checkout.pay.loading,
      paymentMethod: (state) => state.checkout.pay.paymentMethod,
      checkoutInfo: (state) => state.checkout.checkoutInfo,
      midtransMode: (state) => state.cardPayment.midtransMode,
    }),
    midtransClientKey() {
      return process.env.VUE_APP_MIDTRANS_CLIENT_KEY;
    },
    breadcrumbs() {
      const currentName = this.$t('home.sidebarMenu.checkout');
      return [
        {
          text: this.$t('breadcrumb.homepage'),
          to: '/',
        },
        {
          text: currentName,
          to: this.$router.currentRoute.path,
        },
      ];
    },
  },
  created() {
    this.$store.commit('cardPayment/SET_MIDTRANS_MODE', process.env.VUE_APP_MIDTRANS_MODE);
  },
  async mounted() {
    let token = this.$store.state.global.token;
    let newToken = this.$route.query.token;
    if (newToken && token !== newToken) {
      token = decryptToken(newToken);
      await this.$store.dispatch('global/updateToken', token);
      let query = Object.assign({}, this.$route.query);
      delete query.token;
      this.$router.replace({ query });
    }
    let productUuid = this.$route.query.product;
    if (productUuid != null) {
      await this.$store.dispatch('checkout/getCheckoutInfo', {
        product: {
          uuid: productUuid,
        },
        token: token,
      });
    }
  },
  methods: {
    async submit() {
      try {
        if (this.paymentMethod === 'credit_card') {
          let directActivation = null;
          if (this.$route.query) {
            if (this.$route.query.direct_activation) {
              directActivation = this.$route.query.direct_activation;
            }
          }
          const isOrderFree = await this.$store.dispatch('checkout/pay/getIsOrderFree', {
            directActivation: this.$route.query ? this.$route.query.direct_activation : null,
            duration: this.checkoutInfo.duration,
          });
          if (isOrderFree) {
            const data = await this.$store.dispatch('checkout/pay/submit', {
              totalNetPrice: this.checkoutInfo.total_net_price,
              directActivation: this.$route.query ? this.$route.query.direct_activation : null,
              duration: this.checkoutInfo.duration,
              expectFreeOrder: isOrderFree,
            });
            if (data.payment === true) {
              if (data.order_uuid) {
                this.$router.replace(`/how-to-pay/${data.order_uuid}`);
              } else {
                this.$swal(this.$i18n.t('booking.failedPayment'), data.message, 'error');
              }
            } else if (data.payment === false) {
              this.$router.replace(`/order?q=${data.order_number}`);
            }
          } else {
            this.$modal.show('modal-cc-payment', {
              price: this.checkoutInfo.total_net_price,
              directActivation: directActivation,
              duration: this.checkoutInfo.duration,
            });
          }
        } else {
          const data = await this.$store.dispatch('checkout/pay/submit', {
            totalNetPrice: this.checkoutInfo.total_net_price,
            directActivation: this.$route.query ? this.$route.query.direct_activation : null,
            duration: this.checkoutInfo.duration,
          });
          if (data.payment === true) {
            if (data.order_uuid) {
              this.$router.replace(`/how-to-pay/${data.order_uuid}`);
            } else {
              this.$swal(this.$i18n.t('booking.failedPayment'), data.message, 'error');
            }
          } else if (data.payment === false) {
            this.$router.replace(`/order?q=${data.order_number}`);
          }
        }
      } catch (e) {
        console.log('CHECKOUT SUBMIT ERROR: ', e);
      }
    },
  },
};
</script>
